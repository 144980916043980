export const enableAutomation = async (apiCall, scopeId, automationId) => {
  const request = { scopeId, automationId };
  const response = await apiCall("/automation/enable", "POST", request);
  return response && response.success;
};

export const disableAutomation = async (apiCall, scopeId, automationId) => {
  const request = { scopeId, automationId };
  const response = await apiCall("/automation/disable", "POST", request);
  return response && response.success;
};

export const runningAutomation = async (apiCall, scopeId, automationId) => {
  const request = { scopeId, automationId };
  const response = await apiCall("/automation/run", "POST", request);
  return response && response.success;
};

export const connectIntegration = async (apiCall, scopeId, integration) => {
  const request = { scopeId, integration };
  return await apiCall("/integration/connect", "POST", request);
};

export const deleteAutomation = async (
  apiCall,
  scopeId,
  automationId,
  force = false
) => {
  const request = { scopeId, automationId, force };
  const response = await apiCall("/automation/delete", "POST", request);
  return response && response.success;
};

export const listAutomations = async (apiCall, scopeId, cycle, type) => {
  return await apiCall(
    `/automation/list?scope=${scopeId}&cycle=${cycle}&type=${type}`,
    "GET"
  );
};

export const initAutomationEditing = async (
  sendAndWaitWs,
  automationId,
  premadeId,
  premadeVersion,
  draft
) => {
  try {
    const request = {};
    if (premadeId) {
      request.premadeId = premadeId;
      request.premadeVersion = premadeVersion;
    } else if (automationId || draft) {
      if (automationId) {
        request.automationId = automationId;
      }
      if (draft) {
        request.draft = draft;
      }
    } else {
      return null;
    }

    const response = await sendAndWaitWs(
      "/automation/overview/init",
      JSON.stringify(request)
    );
    if (response.error) {
      throw new Error(response.error);
    }
    const jsonData = JSON.parse(response.payload);
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
};

export const getAutomation = async (apiCall, scopeId, automationId) => {
  return await apiCall(
    `/automation/script?scope=${scopeId}&id=${automationId}`,
    "GET"
  );
};

export const formatAutomation = async (apiCall, script, scopeId) => {
  const request = { script, scopeId };
  return await apiCall("/automation/format", "POST", request);
};

export const copyAutomation = async (apiCall, scopeId, automationId, name) => {
  const request = { scopeId, automationId, name };
  const resp = await apiCall("/automation/copy", "POST", request);
  if (resp && !resp.error) {
    return resp;
  } else {
    return null;
  }
};

export const saveAutomationFullData = async (
  apiCall,
  script,
  scopeId,
  automationId = null,
  debug = false
) => {
  const request = {
    script,
    scopeId,
    debug,
  };
  if (automationId) {
    request["automationId"] = automationId;
  }
  return await apiCall("/automation/data/save", "POST", request);
};

export const saveAutomationName = async (
  apiCall,
  scopeId,
  automationId,
  automationName
) => {
  const request = {
    scopeId,
    automationId,
    preferredName: automationName,
  };
  return await apiCall("/automation/data/save", "POST", request);
};

export const replyAutomationDialog = async (apiCall, scopeId, dialog) => {
  dialog.scopeId = scopeId;
  const request = { dialog };
  const response = await apiCall("/dialog/reply", "POST", request);
  return response && response.success;
};

export const getDebugInfo = async (apiCall, scopeId, automationId) => {
  return await apiCall(
    `/automation/debug?tenant=mlink&scope=${scopeId}&id=${automationId}`,
    "GET",
    null,
    "admin"
  );
};

export const batchDelete = async (apiCall, scopeId, automationIds, force) => {
  const request = { scopeId, automationIds, force };
  const response = await apiCall("/dialog/reply", "POST", request);
  return response && response.success;
};
