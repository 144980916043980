import React, { useState, useEffect, useCallback, useMemo } from "react";
import { evaluate } from "@mdx-js/mdx";
import * as runtime from "react/jsx-runtime";
import { BoolInput, TextInput } from "../input/common-inputs";

// Option 1: Define the component first, then memoize it
const DynamicMdxRendererComponent = ({ mdxContent, onInputChange }) => {
  const [Content, setContent] = useState(null);
  const [error, setError] = useState(null);
  const [inputValues, setInputValues] = useState({});

  const handleInputChange = useCallback(
    (varName, newValue) => {
      setInputValues((prev) => ({
        ...prev,
        [varName]: newValue,
      }));
      if (onInputChange) {
        onInputChange(varName, newValue);
      }
    },
    [onInputChange]
  );

  const TextInputWrapper = useMemo(
    () =>
      React.memo(function TextInputComponent(props) {
        const value = props.value || "";
        if (props.value) {
          handleInputChange(props.var, value);
        }
        return (
          <TextInput
            {...props}
            value={value}
            onInputChange={handleInputChange}
          />
        );
      }),
    []
  );

  const BoolInputWrapper = useMemo(
    () =>
      React.memo(function BoolInputComponent(props) {
        const value = props.value === "true" || false;
        handleInputChange(props.var, value);
        return (
          <BoolInput
            {...props}
            value={value}
            onInputChange={handleInputChange}
          />
        );
      }),
    []
  );

  const components = useMemo(
    () => ({
      TextInput: TextInputWrapper,
      BoolInput: BoolInputWrapper,
      a: (props) => <a {...props} target="_blank" rel="noopener noreferrer" />,
    }),
    [TextInputWrapper, BoolInputWrapper]
  );

  useEffect(() => {
    const compileMDX = async () => {
      if (!mdxContent) return;

      try {
        const { default: Content } = await evaluate(mdxContent, {
          ...runtime,
          useDynamicImport: true,
          components,
        });

        setContent(() => Content);
      } catch (err) {
        console.error("MDX compilation error:", err);
        setError(`Failed to compile MDX: ${err.message}`);
      }
    };

    compileMDX();
  }, [mdxContent]);

  if (error) return <div>Error: {error}</div>;
  if (!Content) return <div>Loading...</div>;

  return <Content components={components} />;
};

DynamicMdxRendererComponent.displayName = "DynamicMdxRenderer";

const DynamicMdxRenderer = React.memo(
  DynamicMdxRendererComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.mdxContent === nextProps.mdxContent &&
      prevProps.onInputChange === nextProps.onInputChange
    );
  }
);

export default DynamicMdxRenderer;
