import React, { useState, useEffect } from "react";
import {
  Radio,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  FormControlLabel,
  Chip,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { platformState, userInfoState } from "../global-state";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import LoadingSpinner from "../common/loading";
import MSwitch from "../common/switch";
import { useToast } from "../common/toast";
import Button from "@mui/material/Button";

const HubSetting = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const platform = useRecoilValue(platformState);
  const [userInfo, setUserInfo] = useRecoilState(userInfoState);
  const isApp = platform?.source === "app";
  const { successToast, warningToast, errorToast } = useToast();
  const [candidates, setCandidates] = useState([]);

  // Modify the useEffect to also check for phone connection status
  useEffect(() => {
    if (isApp && window.Android) {
      window.onHubRestarted = (result) => {
        const resp = JSON.parse(result);
        if (!resp.error) {
          successToast("Success");
        } else {
          errorToast("Error!");
        }
        setLoading(false);
      };
      window.onHubList = (result) => {
        const hubList = JSON.parse(result);
        if (!hubList.error) {
          setCandidates(hubList.candidates || []);
        } else {
          setCandidates([]);
        }
        setLoading(false);
      };
      window.onHubElected = (result) => {
        const resp = JSON.parse(result);
        if (!resp.error) {
          successToast("Set hub success");
          handleHubListFetch();
        } else {
          errorToast("Error!");
        }
      };
      window.onHubDismissed = (result) => {
        const resp = JSON.parse(result);
        if (!resp.error) {
          successToast("Remove hub success");
          handleHubListFetch();
        } else {
          errorToast("Error!");
        }
      };
      handleHubListFetch();
    }
  }, [isApp]);

  const handleHubElect = async (elect) => {
    if (window.Android) {
      if (elect) {
        window.Android.electHub();
      } else {
        window.Android.dismissHub();
      }
    }
  };

  const handleHubRestart = async () => {
    if (window.Android) {
      window.Android.restartHub();
    }
  };

  const handleHubListFetch = async () => {
    setLoading(true);
    window.Android.getHubList();
  };

  const canBeToggled = (hub) => {
    return hub.me === true || hub.hubType === "HUB";
  };

  if (!isApp) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">
          This feature is only available in the App.
        </Typography>
      </Box>
    );
  }

  const hasActiveHub = candidates.some((hub) => hub.active);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        p: { xs: 2, md: 3 },
      }}
    >
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Grid container spacing={2}>
          {candidates.map((hub, index) => (
            <Grid item xs={12} key={index}>
              <Card
                sx={{
                  borderRadius: "12px",
                  border: "1px solid #EAEEF0",
                  boxShadow: "none",
                  opacity: canBeToggled(hub) ? 1 : 0.5,
                }}
              >
                <CardContent sx={{ p: 2, "&:last-child": { pb: 2 } }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: "medium" }}
                      >
                        {hub.name}
                      </Typography>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Typography variant="body2" color="textSecondary">
                          {hub.hubType}
                        </Typography>
                        {hub.me && (
                          <Chip
                            label="This Device"
                            size="small"
                            color="primary"
                            sx={{
                              height: "20px",
                              "& .MuiChip-label": {
                                px: 1,
                                fontSize: "0.75rem",
                              },
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                    <FormControlLabel
                      control={
                        <MSwitch
                          size="small"
                          checked={hub.active}
                          onChange={(event) =>
                            handleHubElect(event.target.checked)
                          }
                          disabled={!canBeToggled(hub)}
                        />
                      }
                      label=""
                      onClick={(event) => event.stopPropagation()}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Add Restart Hub Button */}
      {hasActiveHub && !loading && (
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleHubRestart}
            fullWidth
            sx={{
              borderRadius: "8px",
              textTransform: "none",
              py: 1,
            }}
          >
            Restart Hub
          </Button>
        </Box>
      )}

      {/* Info Message Box */}
      <Card
        sx={{
          backgroundColor: theme.palette.background.paper,
          borderRadius: "12px",
          border: "1px solid #EAEEF0",
          boxShadow: "none",
          mt: 2,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: theme.palette.primary.main,
                mr: 2,
              }}
            />
            <Box>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                What is Hub?
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Hub is a central device running mHome software in your local
                net. It&apos;s a communication bridge between your local smart
                home devices and the mHome cloud. And it provides on-device
                engine to process data and run automations locally for stronger
                privacy.
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                Supported hub devices:
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Android phone / tablet with mHome App installed
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default HubSetting;
