import React, { useEffect, useState } from "react";
import { Box, Typography, Button, Tabs, Tab, Chip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRecoilState } from "recoil";
import LoadingSpinner from "../common/loading";
import { settingBackPathState, userInfoState } from "../global-state";
import { getIntegration, installIntegration } from "./integration-service";
import useApiCall from "../common/api-call";
import { useToast } from "../common/toast";
import { useHistory, useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EntityListView from "./entity-list-view";
import GroupListView from "./group-list-view";
import ProviderListView from "./provider-list-view";

// Separate component for Info tab content
const IntegrationInfo = ({
  integrationData,
  location,
  setSettingBackPath,
  history,
}) => {
  const handleLocationSettings = () => {
    setSettingBackPath(location.pathname + location.search + location.hash);
    history.replace("/setting#location");
  };

  const infoContent = {
    "basic.automation": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          System default integration. It can provide automation status and
          control the automations.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {"• Immediately run a task-flow automation"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Enable / disable a recurring automation"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Check automation running status"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Check automation enable / disable status"}
          </Typography>
        </Box>
      </Box>
    ),
    "basic.ui": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          System default integration. It provides capabilities related to the
          App UI and the dashboard.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {"• Send App notification"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Change UI dashboard widget value"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Read UI dashboard widget status"}
          </Typography>
        </Box>
      </Box>
    ),
    "basic.time": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          This integration provides all time related capabilities. It&apos;s
          automatically managed based on your Location settings.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-installed when Location 'Home' is configured"}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-uninstalled when Location 'Home' is not set"}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLocationSettings}
          sx={{ mt: 2 }}
        >
          Location Settings
        </Button>
      </Box>
    ),
    "basic.phone": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          On your mHome Android App (available soon) & iOS App (available
          later), go to &quot;Menu -&gt; Phone Setting &quot; to connect your
          phone and grant the access.
        </Typography>
      </Box>
    ),
    "basic.chat": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          Default integration for chat. Supported capabilities:
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {
              "• Define a Wake-Word as automation trigger, like 'When I say \"good night\", turn off bedroom lights.'"
            }
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {
              "• Ask the assistant to reply a message in the current chat, like 'When I say \"time\", reply the current time in the chat'. Notice, currently the assistant is not allowed to initiate a message in the chat. It can only reply to your requests or wake-words."
            }
          </Typography>
        </Box>
      </Box>
    ),
    "basic.weather": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          Provide weather data. Adding home location can help our agent better
          generate the automation. And select your weather data provider to
          start.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-installed when one provider is connected."}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-uninstalled when no provider is connected."}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLocationSettings}
          sx={{ mt: 2 }}
        >
          Location Settings
        </Button>
      </Box>
    ),
    "basic.device": (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" gutterBottom>
          Connect any device providers to get started.
        </Typography>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-installed when any provide is connected."}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {"• Auto-uninstalled when no available provider connections."}
          </Typography>
        </Box>
      </Box>
    ),
  };

  return infoContent[integrationData.integration] || null;
};

const IntegrationDetailView = ({ showFilter = false, setShowFilter }) => {
  const { apiCall } = useApiCall();
  const theme = useTheme();
  const { successToast, errorToast } = useToast();
  const [userInfo] = useRecoilState(userInfoState);
  const [integrationData, setIntegrationData] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [settingBackPath, setSettingBackPath] =
    useRecoilState(settingBackPathState);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const integrationId = searchParams.get("id");

  const handleLoadIntegration = async () => {
    setLoading(true);
    try {
      const resp = await getIntegration(
        apiCall,
        userInfo.activeScope.id,
        integrationId
      );
      if (resp) {
        setIntegrationData(resp);
      } else {
        errorToast("Error!");
      }
    } catch (error) {
      errorToast("Error!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleLoadIntegration();
  }, [integrationId]);

  const handleGroupChanged = (groups) => {
    setIntegrationData((prev) => ({ ...prev, groups }));
  };

  const getAvailableTabs = () => {
    if (!integrationData) return [];

    const tabs = [];

    if (integrationData?.entityEnabled) {
      tabs.push({
        hash: "entity",
        label: "Entity",
        content: (
          <EntityListView
            meta={integrationData.meta}
            entities={integrationData.entities}
            showFilter={showFilter}
          />
        ),
      });
    }

    if (integrationData?.groupEnabled) {
      tabs.push({
        hash: "group",
        label: "Group",
        content: (
          <GroupListView
            onGroupChanged={handleGroupChanged}
            integrationData={integrationData}
            showFilter={showFilter}
          />
        ),
      });
    }

    if (integrationData?.providerEnabled) {
      tabs.push({
        hash: "provider",
        label: "Provider",
        content: (
          <ProviderListView
            providers={integrationData.providers}
            showFilter={showFilter}
            onLoadIntegration={handleLoadIntegration}
          />
        ),
      });
    }

    tabs.push({
      hash: "info",
      label: "Info",
      content: (
        <IntegrationInfo
          integrationData={integrationData}
          location={location}
          setSettingBackPath={setSettingBackPath}
          history={history}
        />
      ),
    });

    return tabs;
  };

  const goTab = (newHash) => {
    const searchParams = new URLSearchParams(location.search);
    const searchString = searchParams.toString();
    let newUrl = location.pathname;
    if (searchString) {
      newUrl += `?${searchString}`;
    }
    if (newHash) {
      newUrl += `#${newHash}`;
    }
    history.replace(newUrl);
  };

  const handleTabChange = (event, newValue) => {
    const tabs = getAvailableTabs();
    const newHash = tabs[newValue].hash || "";
    goTab(newHash);
    setTabIndex(newValue);
  };

  useEffect(() => {
    if (!integrationData) return;

    const tabs = getAvailableTabs();
    if (!integrationData.installed) {
      goTab("info");
    }

    const hash = location.hash.replace("#", "");
    let newIndex = tabs.findIndex((tab) => tab.hash === hash);
    if (newIndex === -1) newIndex = 0;
    setTabIndex(newIndex);
  }, [integrationData]);

  useEffect(() => {
    const hash = location.hash.replace("#", "");
    let newIndex = tabs.findIndex((tab) => tab.hash === hash);
    if (newIndex === -1) newIndex = 0;
    setTabIndex(newIndex);
  }, [location.hash]);

  const handleInstall = async (integration) => {
    const resp = await installIntegration(
      apiCall,
      userInfo.activeScope.id,
      integration
    );
    if (resp) {
      successToast("Installed");
      handleLoadIntegration();
    } else {
      errorToast("Error!");
    }
  };

  const tabs = getAvailableTabs();

  return (
    <Box sx={{ padding: 2 }}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        integrationData && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="h4">{integrationData.name}</Typography>
              {integrationData.installed ? (
                <Chip
                  label="Installed"
                  color="success"
                  icon={<CheckCircleOutlineIcon />}
                  sx={{
                    backgroundColor: theme.palette.background.l2,
                    color: "white",
                  }}
                />
              ) : (
                <Chip
                  label="Not Installed"
                  color="error"
                  sx={{
                    backgroundColor: theme.palette.background.l4,
                    color: "white",
                  }}
                />
              )}
              {!integrationData.providerEnabled &&
                !integrationData.installed &&
                integrationData.integration !== "basic.time" && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleInstall(integrationData.integration)}
                  >
                    Install
                  </Button>
                )}
            </Box>
            <Typography variant="subtitle1" color="textSecondary">
              {integrationData.org}
            </Typography>

            <Box
              sx={{
                mt: 4,
                pb: "64px",
                overflow: "auto",
              }}
            >
              {tabs[tabIndex]?.content}
            </Box>

            <Box
              sx={{
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                bgcolor: theme.palette.background.paper,
                borderTop: 1,
                borderColor: "divider",
                zIndex: 1100,
              }}
            >
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  minHeight: 48,
                  "& .MuiTabs-scroller": {
                    display: "flex",
                    justifyContent: "center",
                  },
                  "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                  },
                  "& .MuiTabs-scrollButtons": {
                    width: 28,
                    backgroundColor: theme.palette.background.paper,
                  },
                  "& .Mui-selected": {
                    fontWeight: "bold",
                  },
                }}
              >
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    label={tab.label}
                    sx={{
                      minWidth: 100,
                      minHeight: 48,
                      textTransform: "none",
                    }}
                  />
                ))}
              </Tabs>
            </Box>
          </>
        )
      )}
    </Box>
  );
};

export default IntegrationDetailView;
